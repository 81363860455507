<template>
    <v-container>
        <v-row>
            <v-col cols="2">
                <Menu></Menu>
            </v-col>
            <v-col v-if="!loading">
                <v-card class="mb-3">
                    <v-card-text>
                        <v-alert
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                        >
                            Mein Punktestand: 1500
                        </v-alert>

                        <h2 class="mb-2">Meine Daten</h2>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <zw-autocomplete v-model="form.travelers[0].prefix"
                                                 name="prefix"
                                                 label="Anrede"
                                                 :items="getPrefixes() | optionsVL"
                                                 validation="required"
                                ></zw-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-autocomplete v-model="form.travelers[0].title"
                                                 name="title"
                                                 label="Titel"
                                                 :items="getTitles() | optionsVL"
                                                 validation="required"
                                ></zw-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field v-model="form.travelers[0].firstname"
                                               name="firstname"
                                               label="Vorname"
                                               validation="required"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field v-model="form.travelers[0].lastname"
                                               name="lastname"
                                               label="Nachname"
                                               validation="required"
                                ></zw-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <zw-date-picker v-model="form.travelers[0].birthday"
                                                :name="'0_birthday'"
                                                active-picker="YEAR"
                                                label="Geburtsdatum"
                                                :validation="{required: true, regex: /(^\d{2}.\d{2}.\d{4}$)/ }"
                                                :max="(new Date(Date.now())).toISOString().substr(0, 10)"
                                                min="1900-01-01"
                                ></zw-date-picker>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="PLZ"
                                               name="zip"
                                               v-model="form.customer.zip"
                                               validation="required"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Ort"
                                               v-model="form.customer.city"
                                               name="city"
                                               validation="required"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Straße, Nr."
                                               v-model="form.customer.address"
                                               name="address"
                                               validation="required"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Adressezusatz"
                                               name="additional_address"
                                               v-model="form.customer.additional_address"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Telefon"
                                               name="phone"
                                               v-model="form.customer.phone"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="E-mail"
                                               name="email"
                                               v-model="form.customer.email"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-btn
                                    depressed
                                    block
                                    color="primary"
                                    @click="saveCustomer()"
                                >Speichern
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-5 mt-5"></v-divider>

                        <h2 class="mb-2">Password</h2>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Current password"
                                               name="password"
                                               v-model="form.customer.password"
                                               type="password"
                                               :validation="'required'"

                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="New password"
                                               name="new_password"
                                               v-model="form.customer.new_password"
                                               type="password"
                                               :validation="'required'"
                                               vid="new_password2"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <zw-text-field label="Password confirm"
                                               name="new_password2"
                                               v-model="form.customer.new_password2"
                                               type="password"
                                               :validation="'required|is:'+form.customer.new_password"
                                ></zw-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-btn
                                    depressed
                                    block
                                    color="primary"
                                    @click="savePassword()"
                                >Speichern
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-5 mt-5"></v-divider>

                        <template v-if="form.persons>1">
                            <h2 class="mb-2">Mitreisende</h2>

                            <v-list>
                                <template v-for="(traveler,index) in form.travelers">
                                    <v-list-item v-if="index>=1" :key="index">
                                        <template v-if="traveler.edit">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="3">
                                                        <zw-autocomplete v-model="traveler.prefix"
                                                                         name="prefix"
                                                                         label="Anrede"
                                                                         :items="getPrefixes() | optionsVL"
                                                                         validation="required"
                                                        ></zw-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="3">
                                                        <zw-autocomplete v-model="traveler.title"
                                                                         name="title"
                                                                         label="Titel"
                                                                         :items="getTitles() | optionsVL"
                                                                         validation="required"
                                                        ></zw-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="3">
                                                        <zw-text-field v-model="traveler.firstname"
                                                                       name="firstname"
                                                                       label="Vorname"
                                                                       validation="required"
                                                        ></zw-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="3">
                                                        <zw-text-field v-model="traveler.lastname"
                                                                       name="lastname"
                                                                       label="Nachname"
                                                                       validation="required"
                                                        ></zw-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="3">
                                                        <zw-date-picker v-model="traveler.birthday"
                                                                        :name="'0_birthday'"
                                                                        active-picker="YEAR"
                                                                        label="Geburtsdatum"
                                                                        :validation="{required: true, regex: /(^\d{2}.\d{2}.\d{4}$)/ }"
                                                                        :max="(new Date(Date.now())).toISOString().substr(0, 10)"
                                                                        min="1900-01-01"
                                                        ></zw-date-picker>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="9">
                                                        <div class="text-right">
                                                            <v-btn
                                                                depressed
                                                                color="primary"
                                                                @click="saveTraveler(traveler)"
                                                            >Speichern
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ traveler.firstname }} {{ traveler.lastname }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn text color="success"
                                                       @click="editTraveler(index)">
                                                    Bearbeiten
                                                </v-btn>
                                            </v-list-item-action>
                                            <v-list-item-action>
                                                <v-btn text color="error" @click="deleteTraveler(traveler.id)">
                                                    Löschen
                                                </v-btn>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider v-if="index=>0" :key="'d_'+index"></v-divider>
                                </template>
                            </v-list>

                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import Menu from "@/views/profile/menu";
import ZwAutocomplete from "@/components/ZwAutocomplete";
import ZwTextField from "@/components/ZwTextField";
import ZwDatePicker from "@/components/ZwDatePicker";

export default {
    name: 'MyData',
    components: {ZwDatePicker, ZwTextField, ZwAutocomplete, Menu},
    data: () => ({
        loading: true,
        form: {},
        hotel: {},
        booking: {},
    }),
    mounted() {
        const fetchBooking = this.$store.dispatch('Profile/fetchBooking')
        const travelersOptions = this.$store.dispatch('Options/fetchOptions')

        Promise.all([fetchBooking, travelersOptions])
            .then(() => {
                this.$set(this, 'hotel', this.getBooking().hotel)
                this.$set(this, 'booking', this.getBooking().booking)

                this.$root.$children[0].hideLoading()

                // this.$store.dispatch('Travelbox/fetchPersons', this.booking.customValues.hash)
                //     .then(() => {
                //         this.form = this.getPersons()
                //     })
                //     .finally(() => {
                //         this.loading = false
                //         this.$root.$children[0].hideLoading()
                //     })
            })


    },
    methods: {
        ...mapGetters('Travelbox', ['getPersons']),
        ...mapGetters('Profile', ['getBooking']),
        ...mapGetters('Options', ['getGenders', 'getPrefixes', 'getTitles', 'getStatuses']),
        editTraveler(index) {
            this.$set(this.form.travelers[index], 'edit', true)
        },
        saveTraveler(traveler) {
            this.$store.dispatch('Profile/saveTraveler', traveler)
            traveler.edit = false
        },
        saveCustomer() {
            const formData = {
                prefix: this.form.travelers[0].prefix,
                title: this.form.travelers[0].title,
                firstname: this.form.travelers[0].firstname,
                lastname: this.form.travelers[0].lastname,
                birthday: this.form.travelers[0].birthday,
                zip: this.form.customer.zip,
                city: this.form.customer.city,
                address: this.form.customer.address,
                additional_address: this.form.customer.additional_address,
                phone: this.form.customer.phone,
                email: this.form.customer.email,
            }

            this.$store.dispatch('Profile/saveCustomer', formData)
        },
        savePassword() {
            this.$store.dispatch('Profile/changePassword', {
                password: this.form.customer.password,
                new_password: this.form.customer.new_password
            })
        },
        deleteTraveler(id) {
            this.$root.$children[0].$refs.confirm.open('Delete', 'Sind Sie sicher das Sie entfernen möchten?', {color: 'accent'})
                .then((confirm) => {
                    if (confirm) {
                        console.log('ok' + id)
                    }
                })
        }
    },
}
</script>