<template>
    <v-navigation-drawer
        floating
        permanent
    >
        <v-list
            dense
            rounded
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="item.href"
            >
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                link
                @click="logout"
            >
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'Menu',
    data: () => ({
        items: [
            {title: 'Aktuelle Buchung', href: '/profile/booking'},
            {title: 'Meine Daten', href: '/profile/my-data'},
            {title: 'Meine Buchungen', href: '/profile/bookings'},
        ],
    }),
    methods: {
        logout() {
            localStorage.setItem('token','')
            window.axios.defaults.headers.AuthToken = ''
            this.$router.push('/profile')
        }
    }
}
</script>