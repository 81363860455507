<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <validation-provider
                    v-slot="{ errors }"
                    :name="$attrs.name"
                    :rules="$props.validation"
                    :mode="$attrs.mde"
            >
                <v-text-field
                        v-model="inputValue"
                        outlined
                        dense
                        hide-details
                        :label="$attrs.label+' TT.MM.JJJJ'"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @click:append="()=>{modal=true}"
                        :error-messages="errors"
                ></v-text-field>
            </validation-provider>
        </template>
        <v-date-picker
                v-model="calendarValue"
                :active-picker.sync="activePickerInternal"
                locale="de"
                @input="save"
                :first-day-of-week="1"
                v-bind="$attrs"
                v-on="$listeners"
        >
        </v-date-picker>
    </v-dialog>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'ZwDatePicker',
        props: {
            activePicker: {
                default: 'DATE'
            },
            validation: {
                type: [String, Object],
            },
            value: null
        },
        data: () => ({
            activePickerInternal: 'DATE',
            modal: false,
            format: 'DD.MM.YYYY',
            inputValue: null,
            calendarValue: null,
        }),
        methods: {
            save(date) {
                if (!this.$attrs.range || date.length == 2) {
                    this.$refs.dialog.save(date)
                }
                this.$emit('input', date);
            },
        },
        mounted() {
            if(this.value){
                const date = moment(this.value, 'YYYY-MM-DD', true)
                if (date.isValid()) {
                    this.inputValue = date.format(this.format)
                }
            }
        },
        watch: {
            modal(val) {
                val && setTimeout(() => (this.activePickerInternal = this.activePicker))
            },
            value(val) {
                const date = moment(val, 'YYYY-MM-DD', true)
                if (date.isValid()) {
                    this.inputValue = date.format(this.format)
                    this.calendarValue = val
                } else {
                    this.inputValue = null
                    this.calendarValue = null
                }
            },
            inputValue(val) {
                const date = moment(val, this.format, true)
                if (date.isValid()) {
                    this.calendarValue = date.format('YYYY-MM-DD')
                    this.$emit('input', date.format('YYYY-MM-DD'))
                }
            },
        },
    }
</script>